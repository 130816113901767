<template>
  <div id="mian">
    <div class="desc">
      <h5 class="jsxx">报备商户信息修改</h5>
      <div>
        <el-button @click="showDialog" class="editBt" type="primary"
          >录入</el-button
        >
      </div>
    </div>
    <div class="list clear">
      <ul class="listBox">
        <li>
          <span>店铺名称</span>
          <i class="hidden1">
            {{ detail.tradeMchName ? detail.tradeMchName : "" }}
          </i>
        </li>
        <li>
          <span>店铺ID</span>
          <i class="hidden1">{{ detail.storeId ? detail.storeId : "" }}</i>
        </li>
        <li>
          <span>所属商户</span>
          <i class="hidden1">{{ detail.mchName ? detail.mchName : "" }}</i>
        </li>
        <li>
          <span>报备商户号</span>
          <i class="hidden1">
            {{ detail.tradeMchNo ? detail.tradeMchNo : "" }}
          </i>
        </li>
      </ul>
    </div>
    <div class="btn">
      <el-button @click="back" class="back">返回</el-button>
      <el-button @click="next" class="next" type="primary">确定</el-button>
    </div>
    <el-dialog
      title="报备商户信息修改"
      :visible.sync="newShopShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">店铺名称</div>
          <div class="hidden1">
            {{ detail.tradeMchName ? detail.tradeMchName : "" }}
          </div>
          <div class="overAccount_ul_title">所属商户</div>
          <div class="hidden1">{{ detail.mchName ? detail.mchName : "" }}</div>
          <div class="overAccount_ul_title">商户简称</div>
          <el-input
            v-model="formData.storeShortName"
            maxlength="128"
            placeholder="输入商户简称"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <div class="proType">
            <el-radio v-model="formData.productCode" label="wxpay"
              >微信支付</el-radio
            >
            <el-radio v-model="formData.productCode" label="alipay"
              >支付宝支付</el-radio
            >
            <el-radio v-model="formData.productCode" label="unionpay"
              >银联支付</el-radio
            >
          </div>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title">店铺ID</div>
          <div class="hidden1">{{ detail.storeId ? detail.storeId : "" }}</div>
          <div class="overAccount_ul_title">报备商户号</div>
          <div class="hidden1">
            {{ detail.tradeMchNo ? detail.tradeMchNo : "" }}
          </div>
          <div class="overAccount_ul_title">客服电话</div>
          <el-input
            v-model="formData.servicePhone"
            maxlength="12"
            placeholder="输入客服电话"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="newShopShow = false">取 消</el-button>
        <el-button class="addBt" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { setStoresTrade, getStoresTrade } from "@/api/user/shopList.js";
import { payproductsDrop } from "@/api/common.js";
export default {
  data() {
    return {
      newShopShow: false, //新增商户信息展示
      detail: {
        storeId: "",
        storeName: "",
        mchName: "",
        tradeMchNo: "",
        storeShortName: "",
        servicePhone: "",
      },
      formData: {
        storeId: this.$route.query.id,
        tradeMchNo: "",
        storeShortName: "",
        servicePhone: "",
      },
      payproducts: [],
      productList: [],
      productCode: [],
    };
  },
  created() {
    this.getDetail();
    // this.getDrop()
  },
  methods: {
    // 获取详情
    getDetail() {
      getStoresTrade(this.$route.query.id, this.$route.query.tradeMchNo).then(
        (res) => {
          if (res.resultStatus) {
            this.detail = res.resultData;
          }
        }
      );
    },
    // 获取下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res.resultStatus) {
          this.payproducts = res.resultData;
        }
      });
    },
    // 录入展示
    showDialog() {
      this.formData = {
        storeId: this.$route.query.id,
        tradeMchNo: this.$route.query.tradeMchNo,
        storeShortName: "",
        servicePhone: "",
        productCode: "",
      };
      this.newShopShow = true;
    },
    onSubmit() {
      if (
        !/^[0-9\-]+$/.test(this.formData.servicePhone) &&
        !this.formData.storeShortName
      ) {
        this.$message.error("商户简称、客服电话选填一项");
        return;
      }
      if (!this.formData.productCode) {
        this.$message.error("产品类型必选");
        return;
      }
      setStoresTrade(this.$route.query.tradeMchNo, this.formData).then(
        (res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");
            this.getDetail();
            this.newShopShow = false;
          }
        }
      );
    },
    back() {
      this.$router.go(-1);
    },
    next() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.overAccount_ul {
  overflow: hidden;
}
.overAccount_ul .hidden1 {
  height: 19px;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}
.proType {
  display: flex;
  flex-wrap: wrap;
  width: 440px;
}
.proType .el-radio {
  width: 26%;
  margin-bottom: 10px;
}
.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
</style>
